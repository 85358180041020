import { DataObject } from 'o365.modules.DataObject.ts';
import Storage from 'o365.modules.DataObject.Storage.ts';
import EventEmitter from 'o365.modules.EventEmitter.ts';
import { getDataObjectById } from 'o365.vue.ts';
import localStorageHelper from 'o365.modules.StorageHelpers.ts';

declare module 'o365.modules.DataObject.ts' {
    interface DataObject<T> {
        enableBatchData: Function,
        batchData: Array<any>,
        disableBatchData: Function,
        //_batchDataObject: DataObject,
        batchDataObject: BatchDataObject<T>,
        batchIndex: number,
        _batchDataEnabled: boolean,
        isBatchData: boolean,
        mergeBatchData: Function,
        hasNewRecords: boolean
    }
}

DataObject.prototype.isBatchData = true;

DataObject.prototype.mergeBatchData = function () {
    this._batchDataObject.saveChanges();
}
DataObject.prototype.disableBatchData = function () {
    //  this._batchDataEnabled = true;
    this.createNewAtTheEnd = false;
    this._batchDataEnabled = false;
}

DataObject.prototype.enableBatchData = function (useLocalStorage: boolean = false, createNewOnFieldChange = true) {
    if (this._batchDataEnabled) {
        //this._batchDataObject.createNew(true);
        return;
    };
    if (!this._batchDataObject) {
        this._batchDataObject = new BatchDataObject(this, useLocalStorage, createNewOnFieldChange);
    } else {
        this._batchDataObject._initDataFromLocalStorage();
    }
    this._batchDataEnabled = true;
    this.createNewAtTheEnd = true;
    this._batchDataEnabled = true;
    this.on('DataLoaded', () => {
        this.batchDataObject.clearSavedItems();
    });
    //  this.enableBatchData();
    if (this._batchDataObject.data.length === 0 && createNewOnFieldChange ) { this._batchDataObject.createNew(this._batchDataObject.useLocalStorage); }
}

Object.defineProperty(DataObject.prototype, 'hasNewRecords', {
    get: function hasNewRecords() {
        if (!this._batchDataObject) return false;
        if (this._batchDataObject.storage.data.length) return true;
        return false;
    }
});

Object.defineProperty(DataObject.prototype, 'batchDataObject', {
    get: function batchDataObject() {
        return this._batchDataObject;
    }
});
Object.defineProperty(DataObject.prototype, 'batchData', {
    get: function batchData() {
        //if(!this._batchDataObject) return [];
        // if(!this._batchDataObject.storage) return [];
        return this._batchDataObject.data;
    }
});

Object.defineProperty(DataObject.prototype, 'current', {
    get: function current() {
        //if(!this._batchDataObject) return [];
        // if(!this._batchDataObject.storage) return [];
        if (this.currentIndex < 0) {
            if (!this._batchDataObject) return {};
            if (!this._batchDataObject.data.length) return {};
            return this._batchDataObject.data[Math.abs(this.currentIndex) - 1];
        }

        if (!this.storage.data.length) return {};

        return this.storage.data[this.currentIndex];
    }
});


class BatchDataObject<T extends object = any> {
    private _setCurrentIndex: Function;
    private _delete: Function;
    private _save: (pIndex?: number) => Promise<unknown[]>;
    private _cancelChanges: typeof DataObject.prototype.cancelChanges;
    private _dataObject: DataObject<T>;

    private _storageKey: string;
    private _debounce: any;
    private _useLocalStorage: boolean;
    private createNewOnFieldChange: boolean;
    storage: Storage<T>;

    events: EventEmitter<{
        'DataLengthChanged': () => void
    }>;

    get data() {
        return this.storage.data;
    }

    get useLocalStorage() { return this._useLocalStorage; }

    constructor(dataObject: DataObject<T>, useLocalStorage: boolean = true, createNewOnFieldChange = true) {
        const vThat = this;
        this._dataObject = dataObject;
        this._useLocalStorage = useLocalStorage;
        this.createNewOnFieldChange = createNewOnFieldChange;

        this.events = new EventEmitter();

        this.storage = new Storage<T>({
            fields: dataObject.fields.fields,
            createNewAtTheEnd: true,
            dataObjectId: dataObject.id,
            appId: dataObject.appId,
            onFieldChanged: this.fieldChanged,
        });

        this.storage.reindex = function (this: Storage<T>) {
            const vLength = this.items.length;
            for (let i = 0; i < vLength; i++) {
                this.items[i].index = i;
                vThat.extendItem(this.items[i])
            }
        };

        this._initDataFromLocalStorage();

        this.createNew(this._useLocalStorage);

        this._setCurrentIndex = dataObject.setCurrentIndex;
        this._delete = dataObject.deleteItem;
        this._save = dataObject.save;
        this._cancelChanges = dataObject.cancelChanges;
        dataObject.setCurrentIndex = function (pIndex, pSkipCheck) {
            //if batch record, then do change else
            if (pIndex < 0) {
                vThat.data.filter(x => x.current).forEach(item => {
                    vThat.updateItem(item['originalIndex'], { current: false });
                });
                if (pIndex !== this.currentIndex && !vThat._useLocalStorage) {
                    vThat.saveChanges(false);
                }
                const vIndex = vThat.data.findIndex(x => x['batchIndex'] === pIndex);
                vThat.updateItem(vIndex, { current: true });
                vThat._setCurrentIndex.call(dataObject, pIndex);
                return;
            } else if (this.current && this.currentIndex < 0) {
                this.current.current = false;
            }

            if (pIndex !== this.currentIndex && !vThat._useLocalStorage) {
                vThat.saveChanges(false);
            }
            vThat._setCurrentIndex.call(dataObject, pIndex, pSkipCheck);;
        }
        dataObject.deleteItem = function (pItem) {
            const vDs = getDataObjectById(this.id, this.appId);
            const vThat = vDs.batchDataObject;

            if (pItem.isBatchRecord && pItem.index < 0 && (vThat.useLocalStorage || !pItem.PrimKey)) {
                vThat.storage.removeItem(pItem.originalIndex);
                // this.storage.splice(pItem.originalIndex, 1);
                vThat.saveToLocalStorage();
                vThat.events.emit('DataLengthChanged');
                return;
            } 
            return vThat._delete.call(vDs, pItem);
        }

        dataObject.save = function (pIndex?: number) {
            const vDs = getDataObjectById(this.id, this.appId);
            const vThat = vDs.batchDataObject;

            if (pIndex && pIndex < 0) {
                const changes = vThat.storage.changes.filter(item => item.index === pIndex);
                if (changes.length > 0) {
                    return vThat.saveChanges(false, changes);
                }
            }

            return vThat._save.call(vDs, pIndex);
        }

        dataObject.cancelChanges = function (pIndex?: number | null) {
            const vDs = getDataObjectById(this.id, this.appId);
            const vThat = vDs.batchDataObject;
            if (pIndex && pIndex < 0) {
                const vIndex = vThat.data.findIndex(x => x['batchIndex'] === pIndex);
                return vThat.storage.cancelChanges(vIndex);
            }

            return vThat._cancelChanges.call(vDs, pIndex);
        }
    }

    initData() { }

    clearStorage() {
        localStorageHelper.removeItem(this._storageKey);
    }

    clearData(pClearLocalStorage = true) {
        this.storage.clearItems();
        if (pClearLocalStorage) {
            this.clearStorage();
        }
    }

    clearSavedItems() {
        const toRemove: number[] = [];
        this.data.forEach((item, index) => {
            if (!item.isNewRecord) {
                toRemove.unshift(index);
            }
        });
        toRemove.forEach(index => {
            this.storage.items.splice(index, 1);
        });
        this.storage.reindex();

        this.events.emit('DataLengthChanged');
    }

    saveToLocalStorage() {
        if (!this._useLocalStorage) { return; }

        if (this._debounce) clearTimeout(this._debounce);
        this._debounce = window.setTimeout(() => {
            localStorageHelper.setItem(this._storageKey, JSON.stringify(this.storage.changes.map(x => x.changes)));
        })
    }

    saveChanges(pClearStorage = true, pChanges?: any[]) {
        const vDs = getDataObjectById(this._dataObject.id, this._dataObject.appId);
        return vDs.recordSource.saveChanges(pChanges ?? vDs.batchDataObject.storage.changes, null).then(() => {
            if (pClearStorage) {
                this.storage.data.splice(0, this.storage.data.length);
                this.clearStorage();
                this._dataObject.load();
            }
        });
    }

    updateItem(pIndex: number, pValues: any) {
        const vDs = getDataObjectById(this._dataObject.id, this._dataObject.appId);
        const vThat = vDs.batchDataObject;
        vThat.storage.updateItemProps(pIndex, pValues);
    }

    updateStorageItem(pIndex: number, pValues: any, pSkipChangeDetection?: boolean) {
        const vDs = getDataObjectById(this._dataObject.id, this._dataObject.appId);
        const vThat = vDs.batchDataObject;
        vThat.storage.updateItem(pIndex, pValues, pSkipChangeDetection);
    }

    createNew(pValues: any = null, pSetCurrent: boolean = false) {
        const vRecord = this.storage.createNew(pValues);
        this.extendItem(vRecord);
        if (pSetCurrent) { vRecord.current = true; }
    }

    extendItem(item) {
        item.isBatchRecord = true;
        item['originalIndex'] = item.index;
        item['batchIndex'] = (item.index + 1) * -1;
        item.index = (item.index + 1) * -1;
    }

    fieldChanged = (...args) => {
        const vDs = getDataObjectById(this._dataObject.id, this._dataObject.appId);
        const vThat = vDs.batchDataObject;
        if (!vThat) return;
        vDs.emit('FieldChanged', ...args);
        const vEmptyRecords = vThat.storage.data.filter(x => !x.hasChanges && x.isNewRecord);

        let dataLengthHasChanged = false;
        if (vEmptyRecords.length === 0 && this.createNewOnFieldChange) {
            vThat.createNew();
            dataLengthHasChanged = true;
        }
        if (vEmptyRecords.length > 1) {
            vThat.storage.removeItem(vEmptyRecords[0].originalIndex);
            dataLengthHasChanged = true;
            // vThat.storage.removeItem(vEmptyRecords[vEmptyRecords.length - 1].index);
        }
        this.saveToLocalStorage();

        if (dataLengthHasChanged) {
            this.events.emit('DataLengthChanged');
        }
    }

    _initDataFromLocalStorage() {
        if (!this._useLocalStorage) { return; }
        this._storageKey = 'batchData_' + this._dataObject.id;

        try {
            const vLocalData = localStorageHelper.getItem(this._storageKey);
            if (vLocalData) {
                let items = JSON.parse(vLocalData);

                if (items.length === 0) { return; }

                items.forEach(() => this.createNew(undefined, false));

                this.storage.items.forEach((_item, index) => {
                    this.storage.updateItem(index, items[index]);
                });

                items[0].current = true;
            }
        } catch {
            console.warn('Failed to parse data while setting batch storage');
        }
    }
}
